(function ($) {
  /**
   * Basic sub-block toggle for gnav utility templates
   * inside this formatter
   * sub-templates need to have a trigger with class .js-gnav-util-trigger
   * the following div should have .js-gnav-util__content .hidden
   *
   * NOTE: the cart utility link manages its' own toggle so we can render
   * cart content via js when opening
   * ~/fredericmalle_base/template_api/navigation/gnav_cart_v1/gnav_cart_v1.js
   *
   */
  Drupal.behaviors.utilityNavFormatter = {
    attach: function (context) {
      var $trigger = $('.js-gnav-util-trigger', context);
      var $closeTrigger = $('.js-gnav-util-close', context);
      var $blockContent = $('.js-gnav-util__content', context);
      var $body = $('body', context);

      $trigger.on('click', function (event) {
        event.preventDefault();
        $(document).trigger('gnav.readyToClose');
        $body.removeClass('mobile-gnav-active');
        $blockContent.not($(this).next('.js-gnav-util__content')).addClass('hidden');
        if ($(this).hasClass('gnav-util__icon--search')) {
          $body.addClass('gnav-active');
        } else {
          $body.removeClass('gnav-active');
        }
        $('.menu__primary--active').removeClass('menu__primary--active');

        if ($(this).next('.js-gnav-util__content').hasClass('hidden')) {
          $(this).next('.js-gnav-util__content').removeClass('hidden');
          $(this).next('.js-gnav-util__content').find('#search').focus();
          // apply overlay
        } else {
          $(this).next('.js-gnav-util__content').addClass('hidden');
          $body.removeClass('gnav-active');
          // hide overlay
        }
      });

      // content block close
      // js-gnav-util-close
      $closeTrigger.on('click', function (event) {
        event.preventDefault();
        $('.menu__primary--active').removeClass('menu__primary--active');
        $(document).trigger('gnav-util.close');
      });

      $(document).on('click', '.js-content-dim', function () {
        $('.js-gnav-util-close').trigger('click');
      });

      $(document).on('gnav-util.close', function () {
        $blockContent.addClass('hidden');
        $body.removeClass('gnav-active');
        $blockContent.find('input[type=text]').val('');
      });
    }
  };
})(jQuery);
